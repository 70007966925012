<template>
	<div>
		<div class="home">
			<div id="janitorial" class="home__container">
				<div style="margin-bottom:5vh; margin-top:20vh;">
					<!-- <h1>Quality, <em>dependable,</em> on-demand workers with <em>accountable,</em> on-site support</h1> -->
					<h1 class="pb-3">On-Demand Janitorial Staff</h1>
					<p class="page-intro">Working together to make sure your jobs are done on-time and on-budget.</p>
					<div data-aos="fade-in" data-aos-delay="1000">
						<a href="#" v-scroll-to="{el: '#scrollHere', offset: -60, duration: 400}">
							<button class="btn btn__ghost btn__mega mt-5 mr-5">Learn More</button>
						</a>
						<button class="btn btn__primary btn__mega mt-5" @click="rModal()">Get Pricing</button>
					</div>
				</div>
			</div>
			<div class="whiteBlock" id="scrollHere">
				<div class="pageBlock align-center flex-column pb-0 text-center" id="element1">
					<h2 class="pb-5">Flexible Staffing Solutions for Facilities Servicing</h2>
					<p>We understand how criticial it is to your business that your jobs are done right, on-time, and within your budget. We work closely with you each day to make sure that you are fully-staffed at all times and have the capacity to pick up additional business.</p>
				</div>
			</div>
			<div class="greyBlock">
				<div class="pageBlock" id="element">
					<div class="pageBlock__col-1-3 pa-5 flex-column align-center text-center">
						<span><i class="fa-duotone fa-phone-volume fa-4x primaryDark pt-3 pb-5"></i></span>
						<h4 class="mb-3">Call-Offs</h4>
						<p>Call-offs happen and you can't afford to be short-staffed on important jobs. Our local teams are ready to jump in and help, even on short notice.</p>
					</div>
					<div class="pageBlock__col-1-3 pa-5 flex-column align-center text-center">
						<span><i class="fa-duotone fa-football fa-4x primaryDark pt-3 pb-5"></i></span>
						<h4 class="mb-3">Seasonal Reinforcements</h4>
						<p>You may have larger jobs or seasonal jobs where it's just not practical to onboard and manage your own employees. We have the bandwidth to make sure your roster is full.</p>
					</div>
					<div class="pageBlock__col-1-3 pa-5 flex-column align-center text-center">
						<span><i class="fa-duotone fa-people-group fa-4x primaryDark pt-3 pb-5"></i></span>
						<h4 class="mb-3">Regular Daily Workforce</h4>
						<p>Janitors are in high-demand and most companies could use at least a few more of them every single day. We're ready to jump in.</p>
					</div>
				</div>
			</div>
			<div class="whiteBlock">
				<div class="pageBlock pt-5 pb-5 mb-5" id="element2">
					<div class="pageBlock__col-1">
						<img data-aos="fade-in" src="https://firebasestorage.googleapis.com/v0/b/mvpes-25aef.appspot.com/o/AdobeStock_369552688-min.jpeg?alt=media&token=2af602a0-2709-4fee-a8dd-f675948382ef" alt="">
					</div>
					<div class="pageBlock__col-1-text">
						<div>
							<h2 class="mb-2">On-Demand Janitors</h2>
							<h4 class="mb-3">Done right and on-budget</h4>
							<p>Your customers expect every shift to be full and every job to be completed on-time. That's just not always possible given how hard recruiting and retaining good employees has become. We can help.</p>
							<p>Maybe it's a 50+ workers at a stadium or for construction clean up. Maybe you need a couple of extra workers at an office building or school. Or, maybe you are bidding on new business and partner with us to make sure you have the labor resources to get it done. Share with us your needs and we can have a great local team that's ready to jump in when you need them.</p>
						</div>
					</div>
				</div>
				<div class="pageBlock pt-5 pb-5 mb-5 reverse" id="element3">
					<div class="pageBlock__col-1-text">
						<div>
							<h2 class="mb-2">Boosting Your Profitability</h2>
							<h4 class="mb-3">More Workers = More Business</h4>
							<p>We completely understand that you have a tight budget. At the same time, you can't afford to not deliver the results your customers are expecting. Our goal is to put together a pricing plan that maintains your profits and helps you expand.</p>
							<!-- <p>Room service takes longer. Food trays sit in hallways. Morale and quality suffer as your staff has to do more with less. This is where extra professionals can help - on busy days or every day.</p> -->
						</div>
					</div>
					<div class="pageBlock__col-1">
						<img data-aos="fade-in" src="https://firebasestorage.googleapis.com/v0/b/mvpes-25aef.appspot.com/o/AdobeStock_217136634-min.jpeg?alt=media&token=9f136443-55a5-411a-8afa-5b97ec18d960" alt="">
					</div>
				</div>
			</div>

				<div class="whyHire" id="checklist">
					<h2 class="montserrat">Why Partner With <span>J<em>u</em>mp</span> for Janitors?</h2>
					<div class="checklist__wrapper">
						<div class="checklist--item">
							<div class="i-border">
								<i class="fas fa-check fa-2x" data-aos="zoom-in" data-aos-anchor="#checklist" data-aos-delay="300"></i>
							</div>
							<p class="new"><span class="strong">60,000+ Workers</span> - We have over 60,000 workers nationwide, with the capacity to handle just about any request in any city.</p>
							</div>
							<div class="checklist--item">
								<div class="i-border">
									<i class="fas fa-check fa-2x" data-aos="zoom-in" data-aos-anchor="#checklist" data-aos-delay="600"></i>
								</div>
								<p class="new"><span class="strong">Customized Training Modules</span> - Do you or your customers have specific training requirements? We can add that training to our onboarding process.</p>
							</div>
							<div class="checklist--item">
								<div class="i-border">
									<i class="fas fa-check fa-2x" data-aos="zoom-in" data-aos-anchor="#checklist" data-aos-delay="900"></i>
								</div>
								<p class="new"><span class="strong">Dedicated Account Support</span> - You'll work with a dedicated team to ensure that every shift is full and correct, and ever job is done right.</p>
							</div>
							<div class="checklist--item">
								<div class="i-border">
									<i class="fas fa-check fa-2x" data-aos="zoom-in" data-aos-anchor="#checklist" data-aos-delay="1200"></i>
								</div>
								<p class="new"><span class="strong">Shift Management</span> - We create your shifts, manage applications, and handle last-minute changes. We don't expect you to create and staff your own shifts!</p>
							</div>
							<div class="checklist--item">
								<div class="i-border">
									<i class="fas fa-check fa-2x" data-aos="zoom-in" data-aos-anchor="#checklist" data-aos-delay="1500"></i>
								</div>
								<p class="new"><span class="strong">Client Dashboard</span> - Login for easy access to your orders, your staff, and to plan for future shifts.</p>
							</div>
							<div class="checklist--item">
								<div class="i-border">
									<i class="fas fa-check fa-2x" data-aos="zoom-in" data-aos-anchor="#checklist" data-aos-delay="1800"></i>
								</div>
								<p class="new"><span class="strong">Temp-To-Hire</span> - Sometimes you'll find someone who belongs on your team. Enjoy an easy path to directly hire our workers.</p>
							</div>
							<div class="checklist--item">
								<div class="i-border">
									<i class="fas fa-check fa-2x" data-aos="zoom-in" data-aos-anchor="#checklist" data-aos-delay="1800"></i>
								</div>
								<p class="new"><span class="strong">Flex Pricing</span> - We'll customize a pricing plan that fits within your business model and can adapt to different jobs.</p>
							</div>
						</div>
					</div>
				</div>

				<div class="backLightPrimary">
					<div class="pageBlock align-center flex-column pt-5 pb-5 text-center" >
						<h2 style="color:white;" class="pb-5">Get Pricing and More Information About Our Local Workforce</h2>
						<button class="btn btn__primaryDark btn__large" @click="rModal()">Get Started <i class="fa-duotone fa-arrow-right ml-3"></i></button>

						<transition name="modal">
							<QuoteRequest v-if="showQuote" @close="closeModal()" />
						</transition>
					</div>
				</div>


		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex'
import router from '@/router'
import Jumpers from '@/components/Jumpers.vue'
import WorkerBenefits from '@/components/WorkerBenefits.vue'
import QuoteRequest from '@/components/QuoteRequest.vue'
import VenueTypes from '@/components/VenueTypes.vue'

export default {
	name: 'janitorial',
	data() {
    return {
    	showQuote: false,
    	showPricing: false,
    	showInsurance: false,
    	showScreen: false,
    	showManage: false,
    	showDifferent: false,
    }
  },
	components: {
		Jumpers,
		WorkerBenefits,
		VenueTypes,
		QuoteRequest
	},
	methods: {
		rModal() {
      this.showQuote = true
    },
    closeModal() {
      this.showQuote = false
    },
	},
	metaInfo: () => ({
    title: 'Jump',
    titleTemplate: '%s | Janitorial Staffing Platform',
    meta: [{
      vmid: 'description',
      name: 'description',
      content: 'Flexible Staffing Solutions For Your Janitorial Needs',
    }],
  })
}
</script>